import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContainerComponent } from './components/container/container.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: '',
    component: ContainerComponent,
    children: [
      {
        path: 'home',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/home/home.module').then(m => m.HomeModule)
      },
      {
        path: 'academic',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/admission/admission.module').then(m => m.AdmissionModule)
      },
      {
        path: 'class',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/home/home.module').then(m => m.HomeModule)
      },
      {
        path: 'my-teaching',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/my-teaching/my-teaching.module').then(m => m.MyTeachingModule)
      },
      {
        path: 'newsboard',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/newsboard/newsboard.module').then(m => m.NewsboardModule)
      },
      {
        path: 'settings/about-school',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/about-school/about-school.module').then(m => m.AboutSchoolModule)
      },
      {
        path: 'report-s15',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/report-s15/report-s15.module').then(m => m.ReportS15Module)
      },
      {
        path: 'report/student',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/report/report.module').then(m => m.ReportModule)
      },
      {
        path: 'staff',
        loadChildren: () => import('./routes/staff/staff.module').then(m => m.StaffModule)
      },
      {
        path: 'student',
        loadChildren: () => import('./routes/student/student.module').then(m => m.StudentModule)
      },
      {
        path: 'student-group',
        loadChildren: () => import('./routes/class/class.module').then(m => m.ClassModule)
      },
      {
        path: 'settings/about-of-study',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/setting-about-of-study/setting-about-of-study.module').then(m => m.SettingAboutOfStudyModule)
      },
      {
        path: 'settings/grading',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/setting-grading/setting-grading.module').then(m => m.SettingGradingModule)
      },
      {
        path: 'settings/academic-program',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/setting-academic-program/setting-academic-program.module').then(
            m => m.SettingAcademicProgramModule
          )
      },
      // {
      //   path: 'settings/ministry',
      //   canActivate: [AuthGuard],
      //   loadChildren: () =>
      //     import('./routes/setting-ministry/setting-ministry.module').then(m => m.SettingMinistryModule)
      // },
      // {
      //   path: 'settings/calendar',
      //   canActivate: [AuthGuard],
      //   loadChildren: () =>
      //     import('./routes/setting-calendar/setting-calendar.module').then(m => m.SettingCalendarModule)
      // },
      {
        path: 'settings/history-log',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/setting-log-history/setting-log-history.module').then(m => m.SettingLogHistoryModule)
      },
      {
        path: 'settings/shift',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/setting-add-shift/setting-add-shift.module').then(m => m.SettingAddShiftModule)
      },
      {
        path: 'attendance',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/attendance/attendance.module').then(m => m.AttendanceModule)
      },
      {
        path: 'payment',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/payment/payment.module').then(m => m.PaymentModule)
      },
      {
        path: 'feedback',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/feedback/feedback.module').then(m => m.FeedbackModule)
      },
      {
        path: 'development-partnership',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/development-partnership/development-partnership.module').then(
            m => m.DevelopmentPartnershipModule
          )
      },
      {
        path: 'inventory',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/inventory/inventory.module').then(m => m.InventoryModule)
      },
      {
        path: 'request-certificate',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/request-certificate/request-certificate.module').then(m => m.RequestCertificateModule)
      },
      {
        path: 'scholarship',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/scholarship/scholarship.module').then(m => m.ScholarshipModule)
      },
      {
        path: 'certificate',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/certificate/certificate.module').then(m => m.CertificateModule)
      }
    ]
  },
  {
    path: 'login',
    loadChildren: () => import('./routes/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./routes/reset-password/reset-password.module').then(m => m.ResetPasswordModule)
  },
  {
    path: 'change-password',
    loadChildren: () => import('./routes/change-password/change-password.module').then(m => m.ChangePasswordModule)
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
