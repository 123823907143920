import { Injectable, Injector } from '@angular/core';
import { BaseCrudService } from './base-crud.service';
import { Feedback } from '../models/feedback';
import { Observable } from 'rxjs';
import { BaseDatatable } from '../models/datatables/base.datatable';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService extends BaseCrudService<Feedback> {

  constructor(injector: Injector) {
    super(injector);
    this.path = '/studio/feedback';
  }

  readStudent(data: { search?: string; page?: number; limit?: number }): Observable<BaseDatatable<Feedback>> {
    return this.requestService.getJSON(this.path + '/admin/student', { data, is_loading: true });
  }

  readStaff(data: { search?: string; page?: number; limit?: number }): Observable<BaseDatatable<Feedback>> {
    return this.requestService.getJSON(this.path + '/admin/staff', { data, is_loading: true });
  }

  readOne(id: string): Observable<Feedback> {
    return this.requestService.getJSON(this.path + '/admin/' + id, { });
  }

  createFeedback(data: any) {
    return this.requestService.postFileProgress(this.path, { data });
  }

}
