import { NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeedbackDirective } from './components/feedback.directive';
import { FeedbackDialogComponent } from './components/feedback-dialog/feedback-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatQuillModule } from '../mat-quill/mat-quill-module';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [
    FeedbackDirective,
    FeedbackDialogComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatQuillModule,
    TranslateModule
  ],
  schemas: [NO_ERRORS_SCHEMA],
  exports: [
    FeedbackDirective,
    FeedbackDialogComponent
  ],
})
export class FeedbackModule { }
