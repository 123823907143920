<mat-dialog-content align="center">
  <div class="alert-icon-dialog">
    <img [src]="icon" alt="icon.svg" />
  </div>
  <h2 matDialogTitle>{{title | translate}}</h2>
  <span>{{message | translate}}</span>
</mat-dialog-content>
<mat-dialog-actions class="btn-action-dialog">
  <button mat-raised-button matDialogClose="cancel">
    <mat-icon>highlight_off</mat-icon>
    {{button === 'close' ? 'Close': ('button.cancel' | translate)}}
  </button>
  <button *ngIf="button === 'delete'" mat-raised-button matDialogClose="confirm" color="primary">
    <mat-icon>done</mat-icon>
    {{'button.okay' | translate}}
  </button>
  <button *ngIf="button === 'save'" mat-raised-button matDialogClose="confirm" color="primary">
    <mat-icon>save</mat-icon>
    {{'button.save' | translate}}
  </button>
  <button *ngIf="button === 'confirm'" mat-raised-button matDialogClose="confirm" color="primary">
    <mat-icon>check_circle_outline</mat-icon>
    {{'button.confirm' | translate}}
  </button>
  <button *ngIf="button === 'import'" mat-raised-button matDialogClose="confirm" color="primary">
    <mat-icon svgIcon="import-file"></mat-icon>
    {{'button.import' | translate}}
  </button>
  <button *ngIf="button === 'submit'" mat-raised-button matDialogClose="confirm" color="primary">
    <mat-icon svgIcon="send"></mat-icon>
    {{'button.submit' | translate}}
  </button>
  <button *ngIf="button === 'cancel_request'" mat-raised-button matDialogClose="confirm" color="primary">
    <mat-icon>done</mat-icon>
    {{'button.cancel_request' | translate}}
  </button>
  <button *ngIf="button === 'approve'" mat-raised-button matDialogClose="confirm" color="primary">
    <mat-icon>done</mat-icon>
    {{'button.approve' | translate}}
  </button>
  <button *ngIf="button === 'cancel_approved'" mat-raised-button matDialogClose="confirm" color="primary">
    <mat-icon>done</mat-icon>
    {{'button.cancel_approved' | translate}}
  </button>
</mat-dialog-actions>
