import { Directive, ElementRef, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FeedbackDialogComponent } from './feedback-dialog/feedback-dialog.component';

@Directive({
  selector: '[appFeedback]'
})
export class FeedbackDirective {

  @HostListener('click', ['$event'])
  clickEvent() {
    this.dialog.open(FeedbackDialogComponent, {
      width: '790px',
    });
    // if (this.src != '') {
    //   this.dialog.open(ImageDisplayComponent, {
    //     minWidth: '300px',
    //     disableClose: false,
    //     data: this.src,
    //     panelClass: 'transparent',
    //     backdropClass: 'backdropBackground'
    //   });
    // }
  }
  constructor(el: ElementRef, private dialog: MatDialog) {
    el.nativeElement.style.cursor = 'pointer';
  }

}