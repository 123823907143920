import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import Quill from 'quill';
import ImageResize from 'quill-image-resize-module--fix-imports-error';
import { Feedback } from 'src/app/models/feedback';
import { FeedbackService } from 'src/app/services/feedback.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { SnackbarComponent } from 'src/app/shares/snackbar/components/snackbar/snackbar.component';

Quill.register('modules/imageResize', ImageResize);

@Component({
  selector: 'app-feedback-dialog',
  templateUrl: './feedback-dialog.component.html',
  styleUrls: ['./feedback-dialog.component.scss']
})
export class FeedbackDialogComponent implements OnInit {

  form: FormGroup;
  latestHtml: any;
  quillEditorRef: any;
  maxUploadFileSize = 1_000_000;
  imageUrl: string | ArrayBuffer = '';
  imageFile: File;
  imageId: string;
  imageName: string;

  modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    ]
  };

  uploadProgress: number = 0;

  @ViewChild('uploader') input: ElementRef<HTMLInputElement>;

  constructor(
    private fb: FormBuilder,
    private feedbackService: FeedbackService,
    private snackbarService: SnackbarService,
    private dialogRef: MatDialogRef<FeedbackDialogComponent>,
  ) { }

  ngOnInit(): void {
    
    this.initFormGroup();
  }

  initFormGroup() {
    this.form = this.fb.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      attachments: [null]
    });
  }

  changedEditor(event: any) {
    if (event.event == 'text-change') {
      this.latestHtml = event.html;
    }
  }

  pickFile() {
    this.input.nativeElement.click();
  }

  removeFile() {
    this.imageUrl = '';
    this.imageFile = undefined;
    this.imageId = '';
    this.imageName = '';
  }

  onFilePicked(e) {
    const files = e.target.files;
    if (files[0] && !files[0].type.match('image.*')) {
      // check whether the upload is an image
      this.openSnackbar('សូមជ្រើសរើសរូបភាព', true);
      return;
    }
    if (files[0] !== undefined) {
      this.imageName = files[0].name;
      if (this.imageName.lastIndexOf('.') <= 0) {
        return;
      }
      const fr = new FileReader();
      fr.readAsDataURL(files[0]);
      fr.addEventListener('load', () => {
        this.imageUrl = fr.result;
        this.imageFile = files[0];
      });
    } else {
      this.imageUrl = '';
      this.form.get('attachments').markAsTouched();
    }
  }

  private openSnackbar(msg: string, isError?: boolean) {
    this.snackbarService.onShowSnackbar({ message: msg, component: SnackbarComponent, isError: isError });
  }

  onSubmit() {

    if (!this.form.valid) return;

    this.form.markAllAsTouched();
    this.form.value['description'] = this.latestHtml;
    if (this.imageFile) {
      this.form.value['attachments'] = this.imageFile;
    }
  
    this.createFeedback();
  }

  createFeedback() {
    this.feedbackService.createFeedback(this.form.value).subscribe({
      next: (res) => {
        this.uploadProgress = Math.round(res as number);
        const data = res as Feedback;
        if (data.status === 1) {
          this.uploadProgress = 100;
          if (this.uploadProgress == 100) {
            this.snackbarService.onShowSnackbar({
              message: 'Create feedback successfully',
              component: SnackbarComponent,
              isError: false
            });
            this.dialogRef.close(res);
          }
        }
      }
    });
  }

}
