<div class="app-content">
    <div class="app-title">
        <h1 class="title">
            {{'menu.feedback' | translate}}
        </h1>
    </div>
</div>
<mat-dialog-content>
    <div class="creation-form announcement-content">
        <form id="feedback-form" [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="mat-elevation-z0">
                <div>
                    <label for="input" class="required">{{'table.title' | translate}}</label>
                    <mat-form-field appearance="outline">
                        <input matInput formControlName="name" autocomplete="off" placeholder="*"
                            required />
                        <mat-error *ngIf="form.get('name').errors?.is_existed">
                            {{'dialog.name_already_exist' | translate}}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <label for="desc" class="required">{{'form.description' | translate}}</label>
                    <div class="form-control" name="description" class="quill-container form-control" style="position: relative; ">
                        <mat-quill
                            name="description"
                            formControlName="description"
                            class="my-mat-quill"
                            [modules]="modules"
                            (onEditorChanged)="changedEditor($event)">
                        </mat-quill>
                        <input type="file" style="display: none" id="fileInputField" #quillImgUploader />
                    </div>
                    <div class="custom-error of-quill" *ngIf="
                        form.get('description').errors?.required &&
                        form.get('description').touched">
                        <span>{{'dialog.required' | translate}}</span>
                    </div>
                </div>
                <div>
                    <label>{{'user_info.attachment' | translate}}</label>
                    <div class="image-input-container">
                        <div *ngIf="!imageUrl" class="image-container">
                            <img class="img" src="assets/imgs/add-image.svg" alt="announcement-image" />
                            <p>
                                <strong>
                                    {{'dialog.drag_image' | translate}} <span class="browse-file-trigger" (click)="pickFile()">{{'button.browse' | translate}}</span>
                                </strong>
                            </p>
                        </div>
                        <div *ngIf="imageUrl" class="thumbnail-container p-16 p-md-32">
                            <img src="{{imageUrl}}" alt="" class="thumbnail">
                            <div class="img-info">
                                <p><strong class="line-clamp__1">{{imageName}}</strong></p>
                                <span class="btn-delete" (click)="removeFile()">
                                    <mat-icon color="warn">remove_circle</mat-icon>
                                    {{'button.delete' | translate}}
                                </span>
                            </div>
                        </div>
                        <input formControlName="attachments" type="file" id="imageInput" #uploader (change)="onFilePicked($event)"
                            style="display: none" accept="image/png,image/gif,image/jpeg,image/jpg,image/bmp" />
                    </div>
                </div>
            </div>
        </form>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close>
        <mat-icon>cancel</mat-icon>
        {{'button.cancel' | translate}}
    </button>
    <button mat-raised-button color="primary" type="submit" form="feedback-form">
        <mat-icon>send</mat-icon>
        {{'button.send' | translate}}
        <!-- <mat-spinner class="loading-spinner loading-spinner--white" [diameter]="25" *ngIf="isLoading">
        </mat-spinner> -->
    </button>
</mat-dialog-actions>