export default class EnumConstant {
  public static readonly ACTIVE = 1;
  public static readonly INACTIVE = 0;
  public static readonly DISABLED = -2;
  public static readonly APPROVED = 1;
  public static readonly WAITING = 4;
  public static readonly PENDING = 5;
  // public static readonly REJECT = -4;
  public static readonly DELETE = -9;
  public static readonly CANCEL = 9;

  public static readonly PUBLIC = 1;
  public static readonly DRAFT = 2;
  public static readonly DISABLE_OWN = -1;
  public static readonly REQUESTING = 3;
  public static readonly REJECTED = -3;
  public static readonly UNPUBLISHED = -1;
  public static readonly FINISH_STUDY = 8;
  public static readonly QUIT_BEFORE_COURSE = 10;
  public static readonly QUIT_DURING_COURSE = 11;

  public static readonly Attendance = {
    PRESENT: 1,
    ABSENT: 2,
    PERMISSION: 3,
    LATE: 4
  };

  public static readonly Gender = {
    MALE: 'male',
    FEMALE: 'female'
  };

  public static readonly Enrolment = {
    NA: 0,
    PASSED: 1,
    FAILED: -1,
    DELETE_NAME: 9
  };

  public static readonly ReadPermission = {
    PUBLIC: 1,
    PRIVATE: -1
  };

  public static readonly InventoryItemType = {
    NEW: 1,
    USED: 2,
    BROKEN: 3,
    DESTROY: 4
  };

  static Announcement: any;

  public static readonly MaritalStatus = {
    SINGLE: 1,
    MARRIED: 2,
    DIVORCED: 3
  };
  static CourseStatus: any;
}

export enum Role {
  ALL = 'all',
  ADMIN = 'admin',
  SCHOOL = 'school',
  TEACHER = 'teacher',
  STUDENT = 'student',
  FACULTY = 'faculty',
  DEPARTMENT = 'department'
}

export enum RoleId {
  ALL = 0,
  ADMIN = 21,
  SCHOOL = 22,
  FACULTY = 23,
  DEPARTMENT = 24,
  TEACHER = 25,
  STUDENT = 4
}

export enum AcademicType {
  SHORT_COURSE = 'short_course',
  PROGRAM = 'program'
}

export enum Announcement {
  MINISTRY = 'ministry',
  SCHOOL = 'school',
  STUDENT = 'student',
  CLASS = 'class'
}

export enum ClassSubjectStatus {
  DEFAULT = 1,
  ADDED = 0
}

export enum WeekDay {
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
  Sunday = 7
}

export enum Attendance {
  PRESENT = 1,
  ABSENT = 2,
  PERMISSION = 3,
  LATE = 4
}

export enum UserStatusEnum {
  active = 1,
  pending = 0,
  inactive = -1,
  disable = -2
}

export enum HistoryActionEnum {
  RESET_PASSWORD = 'reset password',
  CHANGE_PASSWORD = 'change password',
  LOGIN = 'login',
  CREATE = 'create',
  DELETE = 'delete',
  UPDATE = 'update'
}

export enum HolidayPlatform {
  ALL = 0,
  ADMIN = 1,
  SCHOOL = 2,
  STUDENT = 3
}

export enum ClassEum {
  ACTIVE = 1,
  INACTIVE = 0,
  COMPLETED = 2
}

export enum AnnouncementEnum {
  ALL = 0,
  ADMIN = 1,
  SCHOOL = 2,
  STUDENT = 3
}

export enum RequestAttendanceEnum {
  APPROVED = EnumConstant.APPROVED,
  PENDING = EnumConstant.REQUESTING,
  REJECTED = EnumConstant.REJECTED
}

export enum ExamEnum {
  PASSED = 1,
  FAILED = -1
}

export enum CourseType {
  LONG_COURSE = 1,
  SHORT_COURSE = 2
}

export enum CourseTypeDisplay {
  LONG_COURSE = 'program.long_course',
  SHORT_COURSE = 'program.short_course'
}

export enum CourseStatus {
  DISABLED = EnumConstant.ACTIVE,
  ACTIVE = EnumConstant.DISABLED
}

export enum AttendanceType {
  PRESENT = 1,
  ABSENT = 2,
  PERMISSION = 3
}

export enum BaseKeyAddressEnum {
  BASE_PROVINCE = 'baseProvince',
  BASE_DISTRICT = 'baseDistrict',
  BASE_COMMUNE = 'baseCommune',
  BASE_VILLAGE = 'baseVillage',
  BASE_CURRENT_PROVINCE = 'baseProvince',
  BASE_CURRENT_DISTRICT = 'baseCurrentDistrict',
  BASE_CURRENT_COMMUNE = 'baseCurrentCommune',
  BASE_CURRENT_VILLAGE = 'baseCurrentVillage'
}

export enum YesNoEnum {
  YES = 1,
  NO = -1
}

export enum CertificateTemplateEnum {
  C1_COMPETENCY = 'c1_competency',
  C1_CERTIFICATE = 'c1_certificate',
  C1_COMPETENCY_V2 = 'c1_competency_v2',
  C1_CERTIFICATE_V2 = 'c1_certificate_v2'
}

export enum SecondLanguageEnum {
  NOVICE,
  INTERMEDIATE,
  ADVANCED,
  SUPERIOR,
  DISTINGUISH
}
