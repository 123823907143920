import { Injectable, Injector } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Staff } from '../models/staff';
import { User } from '../models/user';
import { AuthService } from './auth.service';
import { BaseCrudService } from './base-crud.service';
import { BaseDatatable } from '../models/datatables/base.datatable';
import { Roles } from '../models/roles';
import { EmployeeType } from './employee-type';

@Injectable({
  providedIn: 'root'
})
export class ProfileService extends BaseCrudService<User> {
  public staffId: string | undefined;

  constructor(injector: Injector, private authService: AuthService) {
    super(injector);
    this.path = '/studio/account';
  }

  getAccountInfo(): Observable<Staff> {
    return this.requestService.getJSON(this.path + '/info');
  }

  changePassword(data: { old_password: string; new_password: string }) {
    return this.requestService.postJSON(this.path + '/change_password', { data });
  }

  updateMyInfo(data: Staff) {
    return this.requestService.patchFile<Staff>(this.path + '/info', { data }).pipe(
      map(res => {
        this.authService.authChange$.next(true);
        return res;
      })
    );
  }

  getEmployeeType(data: { q?: string; [key: string]: any; page?: number; limit?: number }) {
    return this.requestService.getJSON<BaseDatatable<EmployeeType>>(this.path + '/employment_type', { data, is_loading: true });
  }

  checkExistedUser(data: any) {
    return this.requestService.getJSON(this.path + '/check_existed_user', { data });
  }

  linkEmailRequest(data: { email: string }) {
    return this.requestService.postJSON(this.path + '/link_email_request', { data: data, is_loading: true });
  }

  resendEmailVerification(data: { email_token: string }) {
    return this.requestService.postJSON(this.path + '/resend_link_email_verification', {
      data: data,
      is_loading: true
    });
  }

  linkEmailAccount(data: { email_token: string; verification: string }) {
    return this.requestService.postJSON(this.path + '/link_account', { data: data, is_loading: true });
  }

  requestResetPassword(data: { email: string }) {
    return this.requestService.postJSON(this.path + '/request_reset_password', { data: data, is_loading: true });
  }

  resendResetVerification(data: { email_token: string }) {
    return this.requestService.postJSON(this.path + '/resend_reset_verification', { data: data, is_loading: true });
  }

  verifyResetPassword(data: { email_token: string; verification: string }) {
    return this.requestService.postJSON(this.path + '/verify_reset_password', { data: data, is_loading: true });
  }

  resetPassword(data: { password_token: string; new_password: string }) {
    return this.requestService.postJSON(this.path + '/reset_password', { data: data, is_loading: true });
  }
}
